// extracted by mini-css-extract-plugin
export var column = "ModalHeader__column__E3Hbz";
export var container = "ModalHeader__container___6K_K";
export var flex = "ModalHeader__flex__AYWBk";
export var flexColumn = "ModalHeader__flexColumn__mFwDn";
export var gap1 = "ModalHeader__gap1__d8kmx";
export var gap2 = "ModalHeader__gap2__YPoDq";
export var gap3 = "ModalHeader__gap3___c3el";
export var gap4 = "ModalHeader__gap4__T6Xx0";
export var gap5 = "ModalHeader__gap5__Lk8e2";
export var header = "ModalHeader__header__pX60b";
export var logo = "ModalHeader__logo__JMm2j";
export var row = "ModalHeader__row__b9KY8";